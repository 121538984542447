import request from '@/utils/request/request'
interface MPConfig {
    /**
     * 公众号唯一标识
     */
    appId: string;

    /**
     * 生成签名的时间戳
     */
    timestamp: number;

    /**
     * 生成签名的随机串
     */
    nonceStr: string;

    /**
     * 签名
     */
    signature: string;
}
export interface WechatUserInfo {
    // 保留微信返回的值留作参考 目前只返回了openid 2024-08-09
    /**
     * 用户的唯一标识
     */
    openid: string;

    /**
     * 用户昵称
     */
    nickname: string;

    /**
     * 用户的性别，值为1时是男性，值为2时是女性，值为0时是未知
     */
    sex: number;

    /**
     * 用户个人资料填写的省份
     */
    province: string;

    /**
     * 普通用户个人资料填写的城市
     */
    city: string;

    /**
     * 国家，如中国为CN
     */
    country: string;

    /**
     * 用户头像 最后一个数值代表正方形头像大小（有0、46、64、96、132数值可选，0代表640*640正方形头像)
     */
    headimgurl: string;

    /**
     * 只有在用户将公众号绑定到微信开放平台帐号后，才会出现该字段
     */
    unionid: string;
}
export function getMpConfig() {
    return request.getObject<MPConfig>("/app-api/sysbase/common/wechat/config/common-wx-config");
}

interface WechatAuthResponse {
    /**
     * 用户唯一标识
     */
    openid: string;
}
/** 
 * 获得平台微信支付openid
 * @returns
 */
export function getWeChatUserInfo(code: any): Promise<WechatAuthResponse> {
    return request.getObject<WechatAuthResponse>("/app-api/sysbase/common/wechat/config/common-openid", {
      code: code
    });
}
/** 
 * 获得平台微信支付配置
 * @returns
 */
export function wechatMpConfig(): Promise<any> {
    return request.getObject(`/app-api/sysbase/common/wechat/config/appid`);
  }