import { detectEcology, EnTerminalType } from '@/utils/utils';
import { getWeChatUserInfo } from "@/api/common/wechat";
import type { LocationQuery } from "vue-router";

/**
 * 获取微信授权
 * @param redirectUri 需要授权链接
 */
export function authorize(redirectUri: string) {
  const wxpayPaymentAppid = localStorage.getItem('wxpayPaymentAppid');
  if (!wxpayPaymentAppid || (detectEcology() !== EnTerminalType.weChat && detectEcology() !== EnTerminalType.mini_program)) {
    return;
  }

  const code = getUrlParam(window.location.search, "code");
  const state = getUrlParam(window.location.search, "state");

  if (!code) {
    const appId = JSON.parse(wxpayPaymentAppid);
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=snsapi_base&state=alreadygetcode#wechat_redirect`;
  }
}

export function getWxUserInfo(query: LocationQuery) {
  if ((detectEcology() !== EnTerminalType.weChat && detectEcology() !== EnTerminalType.mini_program)) {
    return;
  }

  const code = query.code;
  const state = query.state;

  if (code) {
    getWeChatUserInfo(code).then((res: any) => {
      localStorage.setItem('wxUserInfo', JSON.stringify(res));
      console.log('getUserToken_OPENID', res);
    });
  }
}

function getUrlParam(search: string, name: string) {
  const params = new URLSearchParams(search);
  return params.get(name);
}