import { type JsApiPayInfo, unifiedOrder } from "@/api/common/pay";
import { getMpConfig } from "@/api/common/wechat";
import wx from "weixin-js-sdk-ts";
import {detectEcology,EnTerminalType} from '@/utils/utils'

// 注入支付权限验证
export function payConfig() {
  if (detectEcology() != EnTerminalType.weChat) {
    // 不在微信内打开不处理
    return;
  }
  // 接口返回是否要验证链接
  getMpConfig().then((data:any) => {
    wx.config({
      debug: false,
      appId: data.appId,
      timestamp: data.timestamp,
      nonceStr: data.noncestr,
      signature: data.signature,
      jsApiList: ["chooseWXPay"],
      openTagList: [],
    });
  });
}

export function jsApiPay(payInfo: JsApiPayInfo): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    if (detectEcology() != EnTerminalType.weChat) {
      // 不在微信内打开不处理
      reject(new Error("不在微信内打开,不可调起支付"));
    } else {
      payConfig();
      wx.ready(() => {
        wx.chooseWXPay({
          timestamp: payInfo.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          nonceStr: payInfo.nonceStr, // 支付签名随机串，不长于 32 位
          package: payInfo.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: payInfo.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign: payInfo.paySign,
          success: (res: any) => {
						console.log(res);
            resolve(res);
          },
          fail: (err: Error) => {
            reject(err);
          },
        });
      });
    }
  });
}

/**
 * 调起支付
 * @param orderId 订单id
 * @returns
 */
export function pay(orderId: String, orderType: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    if (detectEcology() != EnTerminalType.weChat) {
      // 不在微信内打开不处理
      reject(new Error("不在微信内打开,不可调起支付"));
      return;
    } else {
      payConfig();
      wx.ready(() => {
        // 获取支付信息的接口
        unifiedOrder(orderId, orderType)?.then((payInfo:any) => {
          wx.chooseWXPay({
            timestamp: payInfo.timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
            nonceStr: payInfo.nonceStr, // 支付签名随机串，不长于 32 位
            package: payInfo.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: payInfo.signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: payInfo.paySign,
            success: (res: any) => {
              resolve(res);
            },
            fail: (err: Error) => {
              reject(err);
            },
          });
        });
      });
    }
  });
}

/**
 * APP内支付还是H5支付 做个区分
 */
export function payJudgeAPP(
  productId: number,
  isSelf: boolean,
  secUserMobile: string,
  veriCode: string,
  productType: number,
  sourceType: number,
  consignee: string,
  consigneePhone: string,
  consigneeProvince: string,
  consigneeCity: string,
  consigneeRegion: string,
  consigneeDetail: string,
  productCount: number
) {
  // 下单参数
  // 这里的 any 要改为参数
  const placeOrderParam: any = {
    productId: productId,
    vipOrderType: isSelf ? "1" : "2",
    secUserMobile: secUserMobile,
    veriCode: veriCode,
    countryCode: "86",
    productType: productType,
    sourceType: sourceType,
    name: consignee,
    phone: consigneePhone,
    province: consigneeProvince,
    city: consigneeCity,
    region: consigneeRegion,
    detail: consigneeDetail,
    productCount: productCount,
  };
  if (detectEcology() == EnTerminalType.qmxfs_app) {
    if (secUserMobile || consigneePhone) {
      window.history.go(-1);
    }
    // 这里调取APP的方法参考：
    // buyProduct(productId, productType, productCount, 0, JSON.stringify(placeOrderParam));
    return;
  }
  // 接口判断支付成功与否
//   vipWelfarePlaceOrderInfo(placeOrderParam)
//     .then((respone:any) => {
//       if (!respone) {
//         Dialog.alert({ message: "下单失败, 请稍后再试!" });
//         return;
//       }
//       return pay(respone.orderNum);
//     })
//     .then(() => {
//       Toast.success({ message: "购买成功" });
//       if (secUserMobile || consigneePhone) {
//         window.history.go(-1);
//       }
//       // 商品购买成功时
//       courseBuySuccess(productType, productId);
//     })
//     .catch((error:any) => {
//       // 当 error 类型为 Error 时触发弹窗.
//       if (error.message) {
//         Dialog.alert({ message: error.message ?? error });
//       } else {
//         if (error.startsWith("您已购买")) {
//           // 商品购买成功时
//           courseBuySuccess(productType, productId);
//         }
//       }
//     });
}
/**
 * 商品购买成功时
 * @param type 商品类型
 * @param id 商品标识
 */
function courseBuySuccess(type: number, id: number) {
  // 支付成功时要根据各种情况做出不同的交互
}
// 注入支付权限验证
export function shareConfig() {
  if (detectEcology() != EnTerminalType.weChat) {
    // 不在微信内打开不处理
    return;
  }
  getMpConfig().then((data:any) => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
      appId: data.appId, // 必填，公众号的唯一标识
      timestamp: data.timestamp, // 必填，生成签名的时间戳
      nonceStr: data.nonceStr, // 必填，生成签名的随机串
      signature: data.signature, // 必填，签名
      jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的 JS 接口列表
      openTagList: [],
    });
  });
}