import { Interceptors } from './interceptors'
import type { ResponseEntity } from './entity'
import type { AxiosInstance } from 'axios'

export enum HttpMethod {
  GET,
  POST,
  PUT,
  DELETE,
  OPTIONS
}
export class Request {
  axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = new Interceptors().axiosInstance
  }

  /**
   * 获取对象
   * @param url 请求地址
   * @param params 参数
   * @param isShowLoadingView 是否显示 View.
   * @returns
   */
  getObject<T>(url: string, params: object = {}, isShowLoadingView: boolean = true) {
    return new Promise<T>((resolve, reject) => {
      this.axiosInstance
        .get<ResponseEntity<T>>(url, { params: params })
        .then((res) => {
          this.parserResponse(res.data, resolve, reject)
        })
        .catch((err) => {
          reject(err.message)
        })
        .finally(() => {
            return
        })
    })
  }

  postObject<T>(url: string, params = {}, isShowLoadingView: boolean = true) {
    return new Promise<T>((resolve, reject) => {

      this.axiosInstance
        .post<ResponseEntity<T>>(url, params, {})
        .then((res) => {
          this.parserResponse(res.data, resolve, reject)
        })
        .catch((err) => {
          reject(err.message)
        })
        .finally(() => {
            return
        })
    })
  }

  putObject<T>(url: string, params = {}, isShowLoadingView: boolean = true) {
    return new Promise<T>((resolve, reject) => {
      this.axiosInstance
        .put<ResponseEntity<T>>(url, params, {})
        .then((res) => {
          this.parserResponse(res.data, resolve, reject)
        })
        .catch((err) => {
          reject(err.message)
        })
        .finally(() => {
        })
    })
  }
  deleteObject<T>(url: string, params = {}, isShowLoadingView: boolean = true): Promise<T> {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete<ResponseEntity<T>>(url, { params: params })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
            return
        })
    })
  }

  private parserResponse<T>(response: ResponseEntity<T>, resolve: any, reject: any) {
    if (response.code === 0) {
      resolve(response.data)
    } else {
      if (response.code !== -1) {
        if (response.msg !== null && response.msg !== undefined && response.msg != '') {
          // 提示消息.
          if (response.code !== 1004014003) {
            if (response.msg)
              alert(response.msg)
          }
          // 将异常重新发射出去.
          reject(response.msg)
        }
      } else {
        return
      }
    }
  }
}

export default new Request()