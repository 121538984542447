<template>
  <div class="about">
    <!-- <h1>{{ payWeChatView }}</h1> -->
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { authorize, getWxUserInfo } from '@/utils/wechat/authorize'
import { wechatMpConfig, getWeChatUserInfo as getWeChatUserInfoApi } from '@/api/common/wechat';
import { pay } from '@/utils/wechat/jsapi';
import { useRoute } from 'vue-router';

// 接收支付类型、订单号、金额
const route = useRoute();
const payType = ref(route.params.payType as any);
const orderId = ref(route.params.orderId as any);

// const payWeChatView = ref('微信支付页');

onMounted(() => {
  wechatMpConfig().then(async (res: any) => {
    localStorage.setItem('wxpayPaymentAppid', JSON.stringify(res));
    const redirectUri = window.location.href; // 保存当前页面的完整URL

    // 如果已经存在 code，则直接处理
    const code = getUrlParam(window.location.search, 'code');
    if (code) {
      handleCode(code);
    } else {
      // 否则执行授权
      authorize(redirectUri);
    }
  });

  async function handleCode(code: string) {
    const userInfo = await getWeChatUserInfoApi(code); // 调用API获取用户信息
    localStorage.setItem('wxUserInfo', JSON.stringify(userInfo));
    await pay(orderId.value, payType.value);
  }
})

function getUrlParam(search: string, name: string) {
  const params = new URLSearchParams(search);
  return params.get(name);
}
</script>

<style></style>