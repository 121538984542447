/**
 * 是否是微信内打开
 * @returns true | false
 */
export function isWechat(): boolean {
  const ua: any = window.navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger";
}

/**
 * 区分运行生态
 */
export enum EnTerminalType {
  // 区分设备
  iOS = 'iOS', // iPhone iPad
  android = 'android', // 安卓
  // 区分生态
  weChat = 'weChat', // 微信内
  qmxfs_app = 'qmxfs_app', // APP 内
  mini_program = 'mini_program', // 小程序内
  other = 'other', // 其他
}

// 缓存 userAgent 字符串
const userAgent = navigator.userAgent.toLowerCase();

/**
 * 检查 userAgent 中是否存在特定的关键词
 * @param keyword 关键词
 * @returns 是否存在关键词
 */
function containsKeyword(keyword: string): boolean {
  return userAgent.includes(keyword);
}

/**
 * 获取当前设备类型
 * @returns 终端设备类型
 */
export function detectTerminalType(): EnTerminalType {
  if (containsKeyword('iphone') || containsKeyword('ipad') || containsKeyword('ipod') || containsKeyword('ios')) {
    return EnTerminalType.iOS;
  } else if (containsKeyword('android')) {
    return EnTerminalType.android;
  }
  return EnTerminalType.other;
}

/**
 * 获取运行生态
 * @returns 运行生态类型
 */
export function detectEcology(): EnTerminalType {
  if (containsKeyword('micromessenger')) {
    if (containsKeyword('miniprogram')) {
      return EnTerminalType.mini_program;
    }
    return EnTerminalType.weChat;
  } else if (containsKeyword('app qmxfs')) {
    return EnTerminalType.qmxfs_app;
  }
  return EnTerminalType.other;
}