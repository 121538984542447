import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { getWxUserInfo } from '@/utils/wechat/authorize'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:orderId/:payType',
      name: 'home',
      component: HomeView
    }
  ]
})
router.beforeEach((to, from, next) => {
  getWxUserInfo(to.query)
  next()
})


export default router
