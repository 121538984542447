import axios, { type AxiosInstance } from 'axios'

export class Interceptors {
  axiosInstance: AxiosInstance

  constructor() {
    const envMeta = import.meta.env
    this.axiosInstance = axios.create({
      baseURL: 'https://api.sbz.sdjfgj.com/',
      // baseURL: envMeta.VITE_API_BASE_URL,
      timeout: 180000
    })
    this.initInterceptors()
  }

  initInterceptors() {
    // 设置请求头
    this.axiosInstance.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
    // 请求拦截器
    this.axiosInstance.interceptors.request.use(async (config: any) => {
      config.headers.loginType = 3
      return config
    })
    // 响应拦截器
    this.axiosInstance.interceptors.response.use(async (response: any) => {
      return response
    })
  }
}