// 这里主要写一写ts类型：支付相关{订单的下单，支付详情等等}
import request from '@/utils/request/request';
import { detectEcology, EnTerminalType } from '@/utils/utils';

export interface OrderInfo {
    orderId: number;

    /**
     * 订单号
     */
    orderNum: string;

    /**
     * 订单金额
     */
    orderAmount: number;

    /**
     * 商品类型
     */
    commodityType: number;

    /**
     * 商品ID
     */
    commodityId: number;

    /**
     * 商品名称
     */
    commodityName: string;

    /**
     * 订单状态
     */
    state: number;

    /**
     * 支付信息
     */
    signInfo: string;
}
export interface JsApiPayInfo {
    /**
     * 公众号ID
     */
    appId: string;
    /**
     * 时间戳，自1970年以来的秒数
     */
    timeStamp: number;
    /**
     * 用户支付币种
     */
    nonceStr: string;
    /**
     * 预支付交易会话标识
     */
    packageValue: string;
    /**
     * 微信签名方式
     */
    signType: string;
    /**
     * 微信签名
     */
    paySign: string;
}
// 枚举
export enum PayMode {
    /**
     * 商城订单
     */
    GOODS_ORDER = "GOODS_ORDER",
    /**
     * 用户等级升级
     */
    USER_LEVEL_UPGRADE = "USER_LEVEL_UPGRADE",
    /**
     * 金融课堂
     */
    FINANCIAL_COURSE = "FINANCIAL_COURSE",
    /** 
     * 法务咨询
    */
    LEGAL_ADVICE = "LEGAL_ADVICE",
}
export function unifiedOrder(orderId: String, orderType: String): Promise<any> {
    if (detectEcology() == EnTerminalType.other) {
        alert("暂不支持该平台下单!");
        return new Promise((resolve, reject) => {
            reject("暂不支持该平台下单!");
        });
    }
    return request.getObject<string>("/app-api/sysbase/common/payment/wechat/jsapi-pay", {
        orderId: orderId,
        orderType: orderType,
        openid: JSON.parse(localStorage.getItem('wxUserInfo')as any),
        // openid: 'oGyo95s6ZZA4JNMulDCTrVoX3jLc',
    });
}